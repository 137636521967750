
html, body, #root, #map {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.ol-control button{ 
    background-color: #1890ff !important;
	border-color: #ffffff;
	border-radius: 50% !important;
	height: 32px  !important;
	width: 32px  !important;
}
.ol-control button:hover{ 
    background-color: #4ba9ff !important;
	border-color: #ffffff !important;
}

.ol-zoom .ol-zoom-in {
	position: fixed !important;
	top: 10px !important;
	left: 10px !important;
}

.ol-zoom .ol-zoom-out {
	position: fixed !important;
	top: 50px !important;
	left: 10px !important;
}

.ol-rotate-reset {
	position: fixed !important;
	top: 50px !important;
	right: 10px !important;
}

.ol-scale-line, .ol-scale-bar {
	bottom: 35px !important;
}

.ggw-dbo, .ggw-settings{
  opacity: 0.9;
}

div.ant-col.ant-form-item-label {
    padding: 0px; 
	background-color: #dddddd
}
div.ant-row {
	margin-top: 20px;
}

div.ant-drawer-body {
    padding: 8px;
}

div.ant-card-body {
	padding: 10px; 
	padding-top: 10px;
}

div.ant-carousel {
	font-size: 1px;
}

span.ant-radio-disabled + span{
	color:rgba(40, 40, 40, 0.85);
}

span.ant-tree-switcher {
	width: 1px;
}

div.ant-carousel div.slick-slide {
  background: #999999;
  overflow: hidden;	
  text-align: center; 
}

.ol-popup {
	font-size: 150%;
	background-color: white;
	opacity: 0.8;
}		
.ol-popup-content {
	min-width: 250px;
}	
